import { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaRegArrowAltCircleUp,
  FaPlus,
  FaFileDownload,
  FaFilter,
} from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { MdDelete, MdMoreHoriz } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { Button, IconButton, ButtonGroup } from '../../components/button';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
  DropdownLinkOption,
} from '../../components/dropdown';
import { Heading1 } from '../../components/heading';
import { StateModal, TitleModal } from '../../components/modal';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
} from '../../components/section';
import { Table } from '../../components/table';
import { useLimitChange, usePageChange } from '../../components/table/hooks';
import { showSuccessMessage } from '../../module/message';
import {
  batchDeleteTerminal,
  deleteTerminal,
  getTerminalList,
  exportTerminals,
} from '../../module/terminal';
import { getBottlerSimpleList } from '../../module/bottler';
import { getSalesCenterSimpleList } from '../../module/salesCenter';
import { downloadFile } from '../../module/download';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label, Checkbox } from '../../components/form';
import { Select } from '../../components/Select';

const TerminalList = () => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [selectedTerminal, setSelectedTerminal] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [listData, setListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    filters: {
      sn: '',
      bottlerId: [],
      saleCenterId: [],
      outletNumber: '',
      outletName: '',
      nameExtension: '',
      assetNumber: '',
    },
  });
  const [total, setTotal] = useState(1);
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  const handleSelectedChange = (id) => {
    setSelectedTerminal((previous) => {
      const newSelectedTerminal = [...previous];
      const index = previous.indexOf(id);
      if (index === -1) {
        newSelectedTerminal.push(id);
      } else {
        newSelectedTerminal.splice(index, 1);
      }

      return newSelectedTerminal;
    });
  };

  const handleSelectAll = useCallback(() => {
    if (isSelectedAll) {
      setSelectedTerminal([]);
    } else {
      const newSelectedTerminal = [];
      listData.forEach((bottlerGroup) => {
        newSelectedTerminal.push(bottlerGroup.id);
      });
      setSelectedTerminal(newSelectedTerminal);
    }

    setIsSelectedAll(!isSelectedAll);
  }, [isSelectedAll, listData]);

  const handleGetTerminalList = useCallback(() => {
    const requestData = {
      page: listParams.page,
      limit: listParams.limit,
    };
    if (listParams.filters.sn.trim()) {
      requestData.sn = listParams.filters.sn.trim();
    }
    if (listParams.filters.bottlerId.length > 0) {
      requestData.bottlerId = listParams.filters.bottlerId.join(',');
    }
    if (listParams.filters.saleCenterId.length > 0) {
      requestData.saleCenterId = listParams.filters.saleCenterId.join(',');
    }
    if (listParams.filters.outletNumber.trim()) {
      requestData.outletNumber = listParams.filters.outletNumber.trim();
    }
    if (listParams.filters.outletName.trim()) {
      requestData.outletName = listParams.filters.outletName.trim();
    }
    if (listParams.filters.nameExtension.trim()) {
      requestData.nameExtension = listParams.filters.nameExtension.trim();
    }
    if (listParams.filters.assetNumber.trim()) {
      requestData.assetNumber = listParams.filters.assetNumber.trim();
    }

    handleApiResponse(getTerminalList(requestData), (response) => {
      const { total, list } = response.data.data;
      setTotal(total);
      setListData(list);
      setSelectedTerminal([]);
    });
  }, [listParams]);

  const handleBatchDeleteTerminal = (ids) => {
    handleApiResponse(batchDeleteTerminal(ids), () => {
      showSuccessMessage({ message: t('success:RemoveTerminal') });
      handleGetTerminalList();
    });
  };

  const handleDeleteTerminal = useCallback(
    (id) => {
      handleApiResponse(deleteTerminal({ id }), () => {
        showSuccessMessage({ message: t('success:RemoveTerminal') });
        handleGetTerminalList();
      });
    },
    [t, handleGetTerminalList],
  );

  const handleBatchRemove = (ids) => {
    return StateModal({
      type: 'warning',
      title: t('AreYouSure'),
      text: t('DoYouWantToRemoveTheseTerminals'),
      showCancelButton: true,
      confirmButtonText: t('button:Confirm'),
      cancelButtonText: t('button:Cancel'),
      allowDismiss: true,
      onConfirm() {
        handleBatchDeleteTerminal(ids);
      },
    });
  };

  const handleRemove = useCallback(
    (id) => {
      return StateModal({
        type: 'warning',
        title: t('AreYouSure'),
        text: t('DoYouWantToRemoveThisTerminal'),
        showCancelButton: true,
        confirmButtonText: t('button:Confirm'),
        cancelButtonText: t('button:Cancel'),
        allowDismiss: true,
        onConfirm() {
          handleDeleteTerminal(id);
        },
      });
    },
    [t, handleDeleteTerminal],
  );

  const generateActionDropdown = useCallback(
    ({ id }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>

          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/terminal/${id}`}>
                <FiFileText />
                {t('button:Details')}
              </DropdownLinkOption>
            </DropdownItem>
            {userInformation.permissions.includes('terminal.delete') && (
              <DropdownItem onClick={() => handleRemove(id)}>
                <DropdownButtonOption>
                  <MdDelete />
                  {t('button:Remove')}
                </DropdownButtonOption>
              </DropdownItem>
            )}
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [handleRemove, t, userInformation.permissions],
  );

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: t('HardwareSerialNumber'),
        fieldName: 'sn',
      },
      {
        title: t('Bottler'),
        fieldName: 'bottlerName',
      },
      {
        title: t('SalesCenter'),
        fieldName: 'saleCenterName',
      },
      {
        title: t('OutletNumber'),
        fieldName: 'outletNumber',
      },
      {
        title: t('OutletName'),
        fieldName: 'outletName',
      },
      {
        title: t('NameExtension'),
        fieldName: 'nameExtension',
      },
      {
        title: t('AssetNumber'),
        fieldName: 'assetNumber',
      },
      {
        title: t('CreatedTime'),
        fieldName: 'createdAt',
      },
      {
        title: t('Action'),
        custom: true,
        width: '10%',
        align: 'center',
        render(data) {
          return generateActionDropdown(data);
        },
      },
    ];

    if (userInformation.permissions.includes('terminal.delete')) {
      columns.unshift({
        title: (
          <Checkbox
            noMargin
            onChange={handleSelectAll}
            checked={isSelectedAll}
          />
        ),
        fieldName: 'id',
        sortable: false,
        width: '60px',
        render(data) {
          return (
            <Checkbox
              noMargin
              onChange={() => {
                handleSelectedChange(data);
              }}
              checked={selectedTerminal.includes(data)}
            />
          );
        },
      });
    }

    return columns;
  }, [
    t,
    generateActionDropdown,
    userInformation.permissions,
    handleSelectAll,
    isSelectedAll,
    selectedTerminal,
  ]);

  const handleExportList = () => {
    const requestData = {};
    if (listParams.filters.sn.trim()) {
      requestData.sn = listParams.filters.sn.trim();
    }
    if (listParams.filters.bottlerId.length > 0) {
      requestData.bottlerId = listParams.filters.bottlerId;
    }
    if (listParams.filters.saleCenterId.length > 0) {
      requestData.saleCenterId = listParams.filters.saleCenterId;
    }
    if (listParams.filters.outletNumber.trim()) {
      requestData.outletNumber = listParams.filters.outletNumber.trim();
    }
    if (listParams.filters.outletName.trim()) {
      requestData.outletName = listParams.filters.outletName.trim();
    }
    if (listParams.filters.nameExtension.trim()) {
      requestData.nameExtension = listParams.filters.nameExtension.trim();
    }
    if (listParams.filters.assetNumber.trim()) {
      requestData.assetNumber = listParams.filters.assetNumber.trim();
    }

    handleApiResponse(exportTerminals(requestData), (response) => {
      const { url } = response.data.data;

      if (url) {
        handleApiResponse(downloadFile(url), (response) => {
          const urlSegment = url.split('/');
          const fileName = urlSegment[urlSegment.length - 1];
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      }
    });
  };

  const showFilterPanel = () => {
    TitleModal({
      size: 'normal',
      children: (
        <TerminalListFilter
          bottlerSimpleList={bottlerSimpleList}
          listParameters={listParams}
          setListParameters={setListParams}
        />
      ),
    });
  };

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  useEffect(() => {
    handleGetTerminalList();
  }, [handleGetTerminalList]);

  useEffect(() => {
    if (listData.length !== 0 && selectedTerminal.length === listData.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  }, [listData, selectedTerminal]);

  return (
    <>
      <Section noPadding>
        <SectionHeader sticky backgroundFill>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading1>{t('TerminalList')}</Heading1>
            </SectionToolbarItem>
            <SectionToolbarItem>
              {userInformation.permissions.includes('terminal.delete') &&
                selectedTerminal.length > 0 && (
                  <Button
                    secondary
                    onClick={() => handleBatchRemove(selectedTerminal)}
                  >
                    <MdDelete />
                    {t('Remove')}
                  </Button>
                )}
              {userInformation.permissions.includes('terminal.create') && (
                <>
                  <Link to='/terminal/import'>
                    <Button>
                      <FaRegArrowAltCircleUp />
                      {t('button:Import')}
                    </Button>
                  </Link>
                  <Link to='/terminal/create'>
                    <Button>
                      <FaPlus />
                      {t('button:Create')}
                    </Button>
                  </Link>
                </>
              )}

              <Dropdown fixed>
                <Dropdown.Toggle>
                  <Button secondary>
                    <FaFileDownload />
                    {t('button:Export')}
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Content>
                  <DropdownItem onClick={handleExportList}>
                    <DropdownButtonOption>
                      {t('TerminalList')}
                    </DropdownButtonOption>
                  </DropdownItem>
                </Dropdown.Content>
              </Dropdown>

              <Button secondary onClick={showFilterPanel}>
                <FaFilter />
                {t('Filter')}
              </Button>
            </SectionToolbarItem>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={tableColumns}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            total={total}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
            translation={{
              info: t('table.info'),
              empty: t('table.empty'),
            }}
            select={{ type: 'multiple', matcher: 'id' }}
            selected={selectedTerminal}
          />
        </SectionBody>
      </Section>
    </>
  );
};

const TerminalListFilter = ({
  bottlerSimpleList,
  listParameters,
  setListParameters,
  close,
}) => {
  const { t: trans } = useTranslation();
  const [fetchingSalesCenterList, setFetchingSalesCenterList] = useState(false);
  const [salesCenterList, setSalesCenterList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [salesCenterSimpleList, setSalesCenterSimpleList] = useState([]);
  const [searchData, setSearchData] = useState({
    ...listParameters.filters,
  });

  const handleGetSalesCenterList = useCallback((bottlerId, currentPage) => {
    const parameters = {
      page: currentPage,
    };
    handleApiResponse(
      getSalesCenterSimpleList(bottlerId, parameters),
      (response) => {
        const { list, limit, total } = response.data.data;

        setSalesCenterList((previous) => {
          const newSalesCenterList = [...previous.data, ...list];

          return {
            data: newSalesCenterList,
            currentPage: currentPage + 1,
            lastPage: Math.ceil(total / limit),
          };
        });
        setFetchingSalesCenterList(false);
      },
    );
  }, []);

  const handleChangeSearchKeyword = (key, value) => {
    setSearchData({ ...searchData, [key]: value });
  };

  const handleSearch = () => {
    setListParameters((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        filters: searchData,
      };
    });
    close();
  };

  const handleReset = () => {
    setSearchData({
      sn: '',
      bottlerId: [],
      saleCenterId: [],
      outletNumber: '',
      outletName: '',
      nameExtension: '',
      assetNumber: '',
    });
  };

  useEffect(() => {
    if (searchData.bottlerId.length === 0) return;
    if (fetchingSalesCenterList) return;

    if (salesCenterList.currentPage <= salesCenterList.lastPage) {
      handleGetSalesCenterList(
        searchData.bottlerId.join(','),
        salesCenterList.currentPage,
      );
      setFetchingSalesCenterList(true);
    } else {
      setSalesCenterSimpleList(
        salesCenterList.data.map((salesCenter) => {
          return { id: salesCenter.id, text: salesCenter.name };
        }),
      );
    }
  }, [
    searchData.bottlerId,
    fetchingSalesCenterList,
    handleGetSalesCenterList,
    salesCenterList.data,
    salesCenterList.currentPage,
    salesCenterList.lastPage,
  ]);

  // reset sales center related state
  useEffect(() => {
    setSalesCenterList({
      data: [],
      currentPage: 1,
      lastPage: 1,
    });
    setSalesCenterSimpleList([]);
    setSearchData((previous) => {
      return {
        ...previous,
        saleCenterId: [],
      };
    });
  }, [searchData.bottlerId]);

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {trans('Filter')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='hardwareSerialNumber'>
                {trans('HardwareSerialNumber')}
              </Label>
              <Input
                type='text'
                id='hardwareSerialNumber'
                value={searchData.sn}
                onChange={(event) => {
                  handleChangeSearchKeyword('sn', event.target.value);
                }}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='bottler'>{trans('Bottler')}</Label>
              <Select
                fullWidth
                multiple
                options={bottlerSimpleList}
                selected={searchData.bottlerId}
                searchSeparator=','
                onSelect={(id) => {
                  handleChangeSearchKeyword('bottlerId', [
                    ...searchData.bottlerId,
                    id,
                  ]);
                }}
                onDeselect={(id) => {
                  const ids = [...searchData.bottlerId];
                  const index = ids.indexOf(id);
                  if (index > -1) {
                    ids.splice(index, 1);
                    handleChangeSearchKeyword('bottlerId', ids);
                  }
                }}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='salesCenter'>{trans('SalesCenter')}</Label>
              <Select
                fullWidth
                multiple
                options={salesCenterSimpleList}
                selected={searchData.saleCenterId}
                searchSeparator=','
                onSelect={(id) => {
                  handleChangeSearchKeyword('saleCenterId', [
                    ...searchData.saleCenterId,
                    id,
                  ]);
                }}
                onDeselect={(id) => {
                  const ids = [...searchData.saleCenterId];
                  const index = ids.indexOf(id);
                  if (index > -1) {
                    ids.splice(index, 1);
                    handleChangeSearchKeyword('saleCenterId', ids);
                  }
                }}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='outletNumber'>{trans('OutletNumber')}</Label>
              <Input
                type='text'
                id='outletNumber'
                value={searchData.outletNumber}
                onChange={(event) => {
                  handleChangeSearchKeyword('outletNumber', event.target.value);
                }}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='outletName'>{trans('OutletName')}</Label>
              <Input
                type='text'
                id='outletName'
                value={searchData.outletName}
                onChange={(event) => {
                  handleChangeSearchKeyword('outletName', event.target.value);
                }}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='nameExtension'>{trans('NameExtension')}</Label>
              <Input
                type='text'
                id='nameExtension'
                value={searchData.nameExtension}
                onChange={(event) => {
                  handleChangeSearchKeyword(
                    'nameExtension',
                    event.target.value,
                  );
                }}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='assetNumber'>{trans('AssetNumber')}</Label>
              <Input
                type='text'
                id='assetNumber'
                value={searchData.assetNumber}
                onChange={(event) => {
                  handleChangeSearchKeyword('assetNumber', event.target.value);
                }}
              />
            </FormItem>
          </Column>
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={handleReset}>
            {trans('button:Reset')}
          </Button>
          <Button danger onClick={close}>
            {trans('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {trans('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default TerminalList;
