import { callApi } from '../services/api';

const getSalesCenterList = (data) => {
  return callApi.get('/sales-centers', { params: data });
};

const getSalesCenterSimpleList = (bottlerId, data) => {
  return callApi.get(`/bottlers/${bottlerId}/sales-centers/simple`, {
    params: data,
  });
};

const createSalesCenter = (data) => {
  const params = {
    name: data.name,
  };
  if (data.description) {
    params.description = data.description;
  }
  return callApi.post(`/bottlers/${data.bottlerId}/sales-centers`, params);
};

const getSalesCenterDetails = (id) => {
  return callApi.get(`/sales-centers/${id}`);
};

const modifySalesCenter = (id, data) => {
  return callApi.patch(`/sales-centers/${id}`, data);
};

const batchDeleteSalesCenter = (ids) => {
  return callApi.post('/sales-centers/delete-batch', {
    id: ids,
  });
};

const deleteSalesCenter = (id) => {
  return callApi.delete(`/sales-centers/${id}`);
};

const exportSalesCenters = (data) => {
  return callApi.post('/sales-centers/export', data);
};

export {
  getSalesCenterList,
  getSalesCenterSimpleList,
  createSalesCenter,
  getSalesCenterDetails,
  modifySalesCenter,
  batchDeleteSalesCenter,
  deleteSalesCenter,
  exportSalesCenters,
};
